import $ from 'jquery';
import { BasePage } from './base_page';
import mapboxgl from '!mapbox-gl';
import mapboxSdk from '@mapbox/mapbox-sdk';
import geocoding from '@mapbox/mapbox-sdk/services/geocoding';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import turf from 'turf';

export class EstimatesMapPage extends BasePage {
  // @override
  setup () {
    $(document).ready(async function() {
      const mapDataElement = document.querySelector('#map-data');
      const address = mapDataElement ? mapDataElement.dataset.address : null;
      if (!address) {
        return;
      }

      mapboxgl.accessToken = mapDataElement.dataset.apiKey;
      const mapboxClient = mapboxSdk({ accessToken: mapboxgl.accessToken });
      let center;
      if (document.querySelector('#estimate_map_center').value && JSON.parse(document.querySelector('#estimate_map_center').value).length === 2) {
        const coords = JSON.parse(document.querySelector('#estimate_map_center').value);
        center = [Number(coords[0]), Number(coords[1])];
      } else {
        const geocodingService = geocoding(mapboxClient);
        // Get Lat/Lng from address
        const geocodeResult = await geocodingService.forwardGeocode({
          query: address,
          autocomplete: false,
          limit: 1
        }).send();
        center = geocodeResult.body.features[0].center;
        document.querySelector('#estimate_map_center').value = JSON.stringify(center);
      }

      // Create map centered with Lat/Lng, high level zoom to prevent picking up other buildings
      const map = new mapboxgl.Map({ container: 'map', style: 'mapbox://styles/mapbox/streets-v12', center, zoom: 18 });

      // Ensure style is loaded before attempting to add draw object
      await new Promise(resolve => {
        map.once('style.load', resolve);
      });

      const draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true
        }
      });
      
      // Enters polygon edit mode when clicking inside polygon
      map.on('click', (e) => {
        const features = draw.getFeatureIdsAt(e.point);
        if (features[0]) {
          draw.changeMode('direct_select', { featureId: features[0] });
        }
      });

      // Fixes strange issue where bottom of screen turns black in fullscreen mode
      document.addEventListener('fullscreenchange', () => (map.resize()));

      map.addControl(draw, 'top-left');
      map.addControl(new mapboxgl.FullscreenControl());
      map.addControl(new mapboxgl.NavigationControl());

      // Toggle map type
      const mapTypeButtons = document.querySelectorAll('.map-type');
      mapTypeButtons.forEach(button => {
        button.addEventListener('click', function() {
          mapTypeButtons.forEach((el) => el.classList.remove('active'));
          this.classList.add('active');
          if (this.firstChild.value === 'satellite') {
            map.setStyle('mapbox://styles/mapbox/satellite-v9');
            document.querySelector('.mapboxgl-marker')?.classList?.add('d-none');
          } else {
            map.setStyle('mapbox://styles/mapbox/streets-v12');
            document.querySelector('.mapboxgl-marker')?.classList?.remove('d-none');
          }
        });
      });

      const calculateArea = (polygon) => {
        const area = turf.area(polygon);
        document.querySelector('#estimated-area').innerHTML = `${Math.round(area * 100 * 10.7639) / 100} Square Feet`;
        document.querySelector('#estimate_map_area_squares').value = `${Math.round(area * 10.7639) / 100}`;
        document.querySelector('#estimate_map_area_coordinates').value = JSON.stringify(polygon.geometry.coordinates);
      }

      const drawPolgon = (coords) => {
        // Draw polygon on map and zoom out
        if (map.getLayer('outline')) {
          map.removeLayer('outline');
        }
        if (map.getLayer('structure')) {
          map.removeLayer('structure');
          map.removeSource('draw');
          draw.deleteAll();
        }

        map.addSource('draw', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        });

        draw.add({
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: coords
          },
          properties: {
            id: 'structure'
          }
        });
           
        map.addLayer({
          'id': 'structure',
          'type': 'fill',
          'source': 'draw',
          'layout': {},
          'paint': {
            'fill-color': '#0080ff',
            'fill-opacity': 0.5
          }
        });

        map.addLayer({
          'id': 'outline',
          'type': 'line',
          'source': 'draw',
          'layout': {},
          'paint': {
            'line-color': '#000',
            'line-width': 3
          }
        });
      }

      const handleCenterChange = (center) => {
        // Get first map feature, with the high zoom this should be the target building
        const features = map.queryRenderedFeatures({ layers: ['building'], filter: ['==', 'extrude', 'true'] });
        let polygon;
        const point = turf.point(center);
        // If more than one result, build polygon for each feature and find item that contains the geocode result
        const closestMatch = features.length === 1 ? features[0] : features.find((feature) => {
          polygon = turf.polygon(feature.geometry.coordinates);
          return turf.inside(point, polygon)
        });
        if (!closestMatch) {
          document.querySelector('#estimate_map_area_squares').value = 0;
          document.querySelector('#estimate_map_area_coordinates').value = '[]';
          return;
        }
        const coords = closestMatch.geometry.coordinates;
        calculateArea(polygon);
        drawPolgon(coords);
      }
      map.on('load', function() {
        const existingPolygon = document.querySelector('#estimate_map_area_coordinates').dataset.coordinates;
        if (existingPolygon && JSON.parse(existingPolygon).length) {
          let coords = JSON.parse(existingPolygon);
          coords = coords.map((pair) => pair.map((item) => Number(item)));
          const polygon = turf.polygon([coords]);
          calculateArea(polygon)
          drawPolgon(polygon.geometry.coordinates)
        } else {
          handleCenterChange(center);
        }
        const marker = new mapboxgl.Marker({ draggable: true, color: 'blue' })
         .setLngLat(center)
         .addTo(map);
        marker.on('dragstart', () => (document.querySelector('#estimated-area').innerHTML = 'Calculating...' ));

        marker.on('dragend', () => {
          const markerCoords = Object.values(marker.getLngLat());
          handleCenterChange(markerCoords);
          document.querySelector('#estimate_map_center').value = JSON.stringify(markerCoords);
          map.flyTo({ zoom: 20, center: markerCoords });
        });

        // Handle changes to polygon
        map.on('draw.update', (e) => {
          const coords = e.features[0].geometry.coordinates;
          const polygon = turf.polygon(coords);
          calculateArea(polygon);
        });
    
        map.flyTo({ zoom: 20 });
      });
    });
  }
}
