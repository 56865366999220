import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';


export class CalendarSetup {
  static setup() {
    if (document.querySelector('#calendar')) {
      let calendarEl = document.querySelector('#calendar');
      let startTime = $('#sales_event_start_time');
      if (!startTime.length) {
        startTime = $('#batch_sales_event_start_time');
      }
      let endTime = $('#sales_event_end_time');
      if (!endTime.length) {
        endTime = $('#batch_sales_event_end_time');
      }
      const showOnlyUserEventsCheckbox = document.getElementById('show_only_user_events');

      let calendar = new Calendar(calendarEl, {
        events: function(fetchInfo, successCallback, failureCallback) {
          let url = '/sales_events/calendar.json?' + window.location.search.replace('?', '');
          if (showOnlyUserEventsCheckbox) {
            url += 'search[user_id][]=';
            if (showOnlyUserEventsCheckbox.checked) {
              url += showOnlyUserEventsCheckbox.dataset.userId;
            }
          }
          url += `&start_time=${fetchInfo.startStr}&end_time=${fetchInfo.endStr}`;
          fetch(url).then(response => response.json())
            .then(events => successCallback(events))
            .catch(error => failureCallback(error));
        },
        plugins: [dayGridPlugin, bootstrapPlugin, timeGridPlugin, listPlugin, interactionPlugin],
        themeSystem: 'bootstrap',
        initialView: 'timeGridWeek',
        height: '80vh',
        navLinks: true,
        eventMaxStack: 3,
        headerToolbar: {
          left: 'prev,next,today',
          center: 'title',
          right: 'timeGridWeek,timeGridDay,listDay'
        },
        businessHours: {
          startTime: '06:00',
          endTime: '22:00',
        },
        buttonText: {
          timeGridWeek: 'Week',
          timeGridDay: 'Day (Calendar)',
          listDay: 'Day (List)'
        },
        eventDidMount: function(info) {
          $(info.el).attr('title', info.event.extendedProps.hover);
          $(info.el).attr('data-toggle', 'tooltip');
          $(info.el).tooltip();
        },
        dateClick: function(info) {
          startTime.val(moment(info.dateStr).format('YYYY-MM-DDTHH:mm'));
          endTime.val(moment(info.dateStr).add(1, 'hour').format('YYYY-MM-DDTHH:mm'));
          CalendarSetup.addOrUpdateEvent(calendar, startTime, endTime);
        },
        startParam: 'start_time',
        endParam: 'end_time'
      });
      calendar.render();

      if (showOnlyUserEventsCheckbox) {
        showOnlyUserEventsCheckbox.addEventListener('change', () => {
          calendar.refetchEvents(); 
        });
      }

      startTime.on('change', function() {
        if ($(this).val()) {
          calendar.gotoDate($(this).val());
        }
      });
  
      startTime.on('change', function() { CalendarSetup.addOrUpdateEvent(calendar, startTime, endTime); });
      endTime.on('change', function() { CalendarSetup.addOrUpdateEvent(calendar, startTime, endTime); });

      $('.hour-select').on('click', () => {
        CalendarSetup.addOrUpdateEvent(calendar, startTime, endTime);
      });
    }
  }

  static addOrUpdateEvent(calendar, startTime, endTime) {
    let eventId = $('.edit_sales_event').data('event-id') || 'new-event';

    if (startTime.val() && endTime.val()) {
      let existingEvent = calendar.getEventById(eventId);

      if (existingEvent) {
        existingEvent.setStart(startTime.val());
        existingEvent.setEnd(endTime.val());
      } else {
        calendar.addEvent({
          id: eventId,
          title: 'New Event',
          start: startTime.val(),
          end: endTime.val(),
          backgroundColor: 'green',
          textColor: 'white'
        });
      }
    }
  }
}
